<template>
  <v-container 
    tag="section" 
    fluid
    class="mx-auto">
    <validation-observer ref="observer" slim v-slot="{ invalid }">
      <v-row align="center">
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-file-tree"
            :title="title"
            inline
            class="px-5 py-4"
          >
            <v-form class="mt-5">
              <v-row align="center" class="mx-auto">
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" rules="required" id="titulo-grupos-arquivos">
                    <v-text-field
                      label="Título"
                      outlined
                      :error-messages="errors"
                      v-model="nome"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </base-material-card>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="success"
            min-width="100"
            style="float: right;"
            :disabled="invalid"
            :loading="loading"
            @click="salvarGrupo()"
            class="ma-0"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import GruposArquivosService from '@/services/GruposArquivosService';

export default {
  name: "GruposArquivosEdit",

  data() {
    return {
      nome: "",
      loading: false,
    }
  },

  computed: {
    grupoId() {
      return this.$route.params.id ?? null;
    },
    title() {
      return this.grupoId ? "Editando Grupo de Arquivos" : "Adicionar Grupo de Arquivos";
    },
    user() {
      return this.$store.getters.getUser;
    }
  },

  mounted() {
    if (this.grupoId) {
      this.getGrupoArquivos(this.grupoId);
    }
  },

  methods: {
    getGrupoArquivos(id) {
      GruposArquivosService.get(id)
        .then(res => {
          const { nome } = res.data;
          this.nome = nome;
        }).catch(err => {
          console.error(err);
          this.$toast.error('Erro ao recuperar Grupo de Arquivos.', '', { position:'topRight' });
          this.$router.push({ name: 'Grupos de Arquivos' });
        })
    },
    salvarGrupo() {
      const postData = { 
        id: this.grupoId,
        nome: this.nome
      };

      this.loading = true;

      GruposArquivosService.save(postData)
        .then(res => {
          this.$toast.success('Grupo de Arquivos salvo com sucesso.', '', { position:'topRight' });
          this.$router.push({ name: 'Grupos de Arquivos' });
        }).catch(err => {
          console.error(err);
          this.$toast.error('Erro ao salvar Grupo de Arquivos.', '', { position:'topRight' });
        })
        .finally(() => this.loading = false);
    },
  }
}

</script>

<style></style>